import Vue from 'vue'
import App from './App.vue'
import router from './router'
import store from './store'
import $ from 'jquery'
import 'bootstrap/dist/css/bootstrap.css'
import 'bootstrap/dist/js/bootstrap.js'
import BaiduMap from 'vue-baidu-map'

Vue.config.productionTip = false
window.$ = $

new Vue({
  router,
  store,
  render: h => h(App)
}).$mount('#app')



Vue.use(BaiduMap , {
    ak:"vOddFCAOb7aqPtq9aGKb7TGW9NGR39oe"
})
