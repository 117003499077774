<template>
  <div class="trunk">
    <div class="floor">
      <div class="floor-back" style="background-image: url('http://img.hbcygc.net/website/mobile/index/floor1-1.png');">
        <img class="floor-shadow" src="http://img.hbcygc.net/website/mobile/index/floor1-2.png">
      </div>
      <div class="floor-core-absolute">
        <div class="floor-font" :style="{ 'opacity': top_opacity }">
          <p class="title-dyzg">触发想象</p>
          <p class="title-dyzg">启动未来</p>
          <p class="title1">次视代智能眼镜，以精彩画</p>
          <p class="title1">质与精密制程，打造智能眼</p>
          <p class="title1">镜高标准！</p>
        </div>
      </div>
    </div>
    <div class="floor">
      <!-- Carousel -->
      <div id="lb_through" class="carousel slide carousel-fade" data-bs-ride="carousel">
        <!-- The slideshow/carousel -->
        <div class="carousel-inner">
          <div class="carousel-item active" data-bs-interval="2000"
            style="background-image: url('https://img.hbcygc.net/website/t1/homes/mp2.png');">
            <div class="floor-core-item" style="justify-content: flex-end;padding-bottom: 3rem;">
              <div class="floor-font">
                <p class="title3">M.vision<span style="color: #0396BC;">穿越号</span></p>
                <p class="title2">轻装出行时尚</p>
                <p class="title2">精彩跃然镜上</p>
                <div class="floor-btns">
                  <router-link to="/Appointment?productId=cdfc1061-0ccd-11ee-b565-00163e2ab162" class="floor-btns-a btn-through">立即预约</router-link>
                  <router-link to="/Through" class="floor-btns-a btn-black">了解更多</router-link>
                </div>
              </div>
            </div>
          </div>
          <div class="carousel-item" data-bs-interval="2000"
            style="background-image: url('https://img.hbcygc.net/website/t1/homes/mp1.png');">
            <img class="floor-shadow" src="http://img.hbcygc.net/website/mobile/index/floor2-2-2.png">
            <div class="floor-core-item" style="justify-content: flex-end;padding-bottom: 3rem;">
              <div class="floor-font">
                <p class="title3">M.vision<span style="color: #0396BC;">穿越号</span></p>
                <p class="title2">轻装出行时尚</p>
                <p class="title2">精彩跃然镜上</p>
                <div class="floor-btns">
                  <router-link to="/Appointment?productId=cdfc1061-0ccd-11ee-b565-00163e2ab162" class="floor-btns-a btn-through">立即预约</router-link>
                  <router-link to="/Through" class="floor-btns-a">了解更多</router-link>
                </div>
              </div>
            </div>
          </div>
          <!-- <div class="carousel-item" data-bs-interval="2000"
            style="background-image: url('http://img.hbcygc.net/website/mobile/index/floor2-3-1.png');">
            <img class="floor-shadow" src="http://img.hbcygc.net/website/mobile/index/floor2-3-2.png">
            <div class="floor-core-item" style="justify-content: flex-end;padding-bottom: 3rem;">
              <div class="floor-font">
                <p class="title3">M.vision<span>穿越号</span></p>
                <p class="title2">轻装出行时尚</p>
                <p class="title2">精彩跃然镜上</p>
                <div class="floor-btns">
                  <router-link to="/Appointment?productId=cdfc1061-0ccd-11ee-b565-00163e2ab162" class="floor-btns-a btn-pink">立即预约</router-link>
                  <router-link to="/Through" class="floor-btns-a">了解更多</router-link>
                </div>
              </div>
            </div>
          </div> -->
        </div>

        <!-- Left and right controls/icons -->
        <button class="carousel-control-prev" type="button" data-bs-target="#lb_through" data-bs-slide="prev">
          <span class="carousel-control-prev-icon"></span>
        </button>
        <button class="carousel-control-next" type="button" data-bs-target="#lb_through" data-bs-slide="next">
          <span class="carousel-control-next-icon"></span>
        </button>
      </div>

    </div>
    <div class="floor">
      <div class="floor-back-sticky"
        style="background-image: url('http://img.hbcygc.net/website/mobile/index/floor3.png');">
      </div>
      <div class="floor-core-sticky">
        <div class="floor-core-item" style="justify-content: flex-end;padding-bottom: 3rem;">
          <div class="floor-font">
            <p class="title3">M.vision<span style="color:#BAF848 ;">巨幕号</span></p>
            <p class="title2">畅享超大巨幕</p>
            <p class="title2">体验全面震撼</p>
            <div class="floor-btns">
              <router-link to="/Screen" class="floor-btns-a">了解更多</router-link>
            </div>
          </div>
        </div>
      </div>
    </div>
    <div class="floor">
      <div class="floor-back-sticky"
        style="background-image: url('http://img.hbcygc.net/website/mobile/index/floor4.png');">
      </div>
      <div class="floor-core-sticky">
        <div class="floor-core-item" style="justify-content: flex-end;padding-bottom: 3rem;">
          <div class="floor-font">
            <p class="title3">M.vision<span style="color: #2D4EC8;">未来号</span></p>
            <p class="title2">率先走近虚实</p>
            <p class="title2">轻松触达未来</p>
            <div class="floor-btns">
              <router-link to="/Future" class="floor-btns-a">了解更多</router-link>
            </div>
          </div>
        </div>
      </div>
    </div>
    <div class="floor">
      <div class="floor-back-sticky"
        style="background-image: url('http://img.hbcygc.net/website/mobile/index/floor5.png');">
      </div>
      <div class="floor-core-sticky">
        <div class="floor-core-item" style="justify-content: flex-end;padding-bottom: 3rem;">
          <div class="floor-font">
            <p class="title2">Future OS</p>
            <p class="title1">完整的操作系统纵享多重</p>
            <p class="title1">交互形态兼容现有技术 对</p>
            <p class="title1">接简单快捷</p>
            <div class="floor-btns">
              <router-link to="/Os" class="floor-btns-a">了解更多</router-link>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<style lang="less">
  @font-face {
  font-family: "sy";
  src: url('../font-familys/SourceHanSansCN-Heavy.ttf')
  }

  .title2{
    font-family: 'sy';
  }
</style>

<script>
export default {
  data() {
    return {
      top_opacity: 1
    }
  },
  mounted() {
    window.addEventListener("scroll", (e) => { this.ergodic(); });
  },
  methods: {
    ergodic: function () {
      var sh = window.screen.height - 300;
      var s_Top = $(document).scrollTop();
      if (s_Top < sh) {
        this.top_opacity = (sh - s_Top) / sh;
      }
    }
  }
}
</script>
