<template>
  <div id="app">
    <div v-show="isBigFont" class="zzqb">
      <div>
        <p>视窗太小，请调整浏览器大小比例</p>
      <p>然后再尝试刷新页面！</p>
      <p><a href="javascript:location.reload();">点击刷新</a></p>
      </div>
    </div>
    <div v-show="!isBigFont" class="cct">
      <nav>
      <div class="top-menu to-menu" data-bs-toggle="offcanvas" data-bs-target="#left-menu">
        <div>
          <svg xmlns="http://www.w3.org/2000/svg" fill="currentColor" class="bi bi-justify" viewBox="0 0 16 16">
            <path fill-rule="evenodd"
              d="M2 12.5a.5.5 0 0 1 .5-.5h11a.5.5 0 0 1 0 1h-11a.5.5 0 0 1-.5-.5zm0-3a.5.5 0 0 1 .5-.5h11a.5.5 0 0 1 0 1h-11a.5.5 0 0 1-.5-.5zm0-3a.5.5 0 0 1 .5-.5h11a.5.5 0 0 1 0 1h-11a.5.5 0 0 1-.5-.5zm0-3a.5.5 0 0 1 .5-.5h11a.5.5 0 0 1 0 1h-11a.5.5 0 0 1-.5-.5z" />
          </svg>
        </div>
      </div>

      <router-link to="/My" class="top-menu to-my">
        <div>
          <svg xmlns="http://www.w3.org/2000/svg" width="21" height="21" fill="currentColor" viewBox="0 0 16 16"
            class="bi bi-person">
            <path
              d="M8 8a3 3 0 1 0 0-6 3 3 0 0 0 0 6zm2-3a2 2 0 1 1-4 0 2 2 0 0 1 4 0zm4 8c0 1-1 1-1 1H3s-1 0-1-1 1-4 6-4 6 3 6 4zm-1-.004c-.001-.246-.154-.986-.832-1.664C11.516 10.68 10.289 10 8 10c-2.29 0-3.516.68-4.168 1.332-.678.678-.83 1.418-.832 1.664h10z">
            </path>
          </svg>
        </div>
      </router-link>
    </nav>
    <div class="offcanvas offcanvas-start" id="left-menu">
      <div class="logo">
        <router-link to="/"><img src="https://img.hbcygc.net/website/pc/images/logo.png"></router-link>
      </div>
      <router-link class="m-title" style="margin: 1rem 0 0 0;" to="/">首页</router-link>
      <a href="javascript:;" class="m-title test-fontsize">只见未来</a>
      <router-link class="m-item" to="/Through">穿越号</router-link>
      <router-link class="m-item" to="/Screen">巨幕号</router-link>
      <router-link class="m-item" to="/Future">未来号</router-link>
      <a href="javascript:;" class="m-title">Future Os</a>
      <router-link class="m-item" to="/Fos">FOS简介</router-link>
      <router-link class="m-item" to="/Ar">Future AR</router-link>
      <router-link class="m-item" to="/Sdk">Future 开发者联盟</router-link>
      <a href="javascript:;" class="m-title">关于我们</a>
      <router-link class="m-item" to="/5g">5G双摄手机</router-link>
      <router-link class="m-item" to="/Meta">未来元宇宙</router-link>
      <router-link class="m-item" to="/Company">公司简介</router-link>
      <router-link class="m-item" to="/About">联系我们</router-link>
    </div>
    <router-view />
    <footer>
      <div class="footer-menu">
        <div class="footer-menu-child">
          <a href="javascript:;" class="footer-title">只见未来</a>
          <router-link to="/Through">穿越号</router-link>
          <router-link to="/Screen">巨幕号</router-link>
          <router-link to="/Future">未来号</router-link>
          <router-link to="/5g">3D双摄手机</router-link>
        </div>
        <div class="footer-menu-child">
          <a href="javascript:;" class="footer-title">未来全屏通</a>
          <router-link to="/Fos">FOS简介</router-link>
          <router-link to="/Ar">Future AR</router-link>
          <router-link to="/Sdk">Future 开发者联盟</router-link>
        </div>
        <div class="footer-menu-child">
          <a href="javascript:;" class="footer-title">关于我们</a>
          <router-link to="/Meta">未来元宇宙</router-link>
          <router-link to="/Company">公司简介</router-link>
          <router-link to="/About">联系我们</router-link>
        </div>
      </div>
      <div class="floor-btns footer-btns">
        <router-link class="floor-btns-a" to="/Consult">立即咨询</router-link>
      </div>
      <div class="footer-end">
        <p>武汉东湖新技术开发区雄庄路8号曙光星城D区2号楼1001室</p>
        <p>Copyright 2020-2023 Future Fullscreen Intelligent Technology Group Co., Ltd.鄂ICP备2023002274号-1</p>
        <p class="ba">
          <img src="./assets/police-badge.png" />
          <a href="https://beian.mps.gov.cn/#/query/webSearch?code=42018502007119" rel="noreferrer"
            target="_blank">鄂公网安备42018502007119</a>
        </p>
      </div>
    </footer>
    </div>

  </div>
</template>

<style lang="less">
@font-face {
  font-family: "dyzg";
  src: url('./font-familys/douyuzhuiguang.ttf')
}

@font-face {
  font-family: "almm";
  src: url('./font-familys/AlimamaFangYuanTiVF-Thin.ttf')
}


html {
  --antd-wave-shadow-color: transparent !important;
}

body {
  position: relative;
  z-index: -300;
  // overflow-x: hidden;
}

body .ms-mobile-select {
  position: fixed;
}

#app {
  position: relative;
  z-index: -200;
  scrollbar-width: none;
}

.cct{
  position: relative;
  z-index: -150;
}

::-webkit-scrollbar {
  display: none;
}

.zzqb{
  width: 100vw;
  height: 100vh;
  position: fixed;
  left: 0;
  top: 0;
  z-index: 1000;
  background-color: #5d5c5c;
  display: flex;
  justify-content: center;
  align-items: center;
  text-align: center;
  p{
    color: #F5F5F5;
    font-size: 4vw;
  }
}

nav {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 5rem;
  display: flex;
  justify-content: space-between;
  padding: 1rem 1rem;
  z-index: 100;
}

nav .top-menu {
  width: 3rem;
  height: 3rem;
  border-radius: 50%;
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: rgba(0, 0, 0, .1);
  backdrop-filter: blur(20px);
  cursor: pointer;
  color: white;
}

nav .top-menu:active {
  color: white;
}

nav .top-menu:visited {
  color: white;
}

nav .top-menu:hover {
  color: white;
}

nav .to-my {
  top: 0;
  right: 0;
}


nav svg {
  width: 2rem;
  height: 2rem;
}

#left-menu {
  padding: 2rem;
  overflow-y: auto;
  scrollbar-width: none;
  font-family: 'almm';
}

#left-menu a {
  text-decoration: none;
  color: #F5F5F5;
  padding-bottom: 0.5rem;
  margin-bottom: 1rem;

  &.router-link-exact-active {
    color: #B3E5FC;
  }
}

#left-menu .m-title {
  font-weight: bold;
  margin-top: 0.5rem;
}

#left-menu .m-item {
  border-bottom: 1px solid #f5f5f51f;
  font-size: 0.8rem;
}

#left-menu .logo {
  // border: 1px solid red;
  position: relative;
  z-index: 10;
  width: 100%;
}

footer {
  width: 100%;
  height: auto;
  font-size: 1rem;
  background-image: url('https://img.hbcygc.net/website/mobile/images/bottom.png');
  background-position: 50%;
  background-repeat: no-repeat;
  background-size: cover;
  position: relative;
  z-index: -1;
  font-family: 'almm';
}


#app .offcanvas {
  background: hsla(0, 0%, 100%, .4);
  box-shadow: 2px 0 20px rgba(0, 0, 0, .1);
  -webkit-backdrop-filter: blur(24px);
  backdrop-filter: blur(24px);
  max-width: 60%;
}

.footer-menu {
  width: 100%;
  height: auto;
  padding: 15% 0 0 5%;
}

.footer-menu-child {
  width: 100%;
  height: auto;
  margin-bottom: 3rem;
}

.footer-menu-child a {
  display: block;
  text-decoration: none;
  color: white;
  margin: 1.5rem;
  font-size: 1rem;

  &.router-link-exact-active {
    color: #B3E5FC;
  }
}

.footer-title {
  font-size: 1.5rem;
  font-weight: bold;
}

.footer-btns {
  margin: 3rem 0;
}

.footer-end {
  width: 100%;
  // height: 10rem;
  padding: 0 2rem 1rem 2rem;
  text-align: center;
  color: rgba(255, 255, 255, 0.8);
  font-family: Avenir, Helvetica, Arial, sans-serif;

  p,
  a {
    font-size: 0.8rem;
    color: #9E9E9E;
  }

  a {
    text-decoration: none;
  }

  .ba {
    display: flex;
    justify-content: center;
    column-gap: 0.5rem;

    img {
      width: auto;
      height: 1rem;
    }
  }
}

.trunk {
  position: relative;
  z-index: -100;
  width: 100%;
  min-height: 100vh;
}

.title-dyzg {
  font-size: 12vw;
  font-family: "dyzg";
}

.title1 {
  font-size: 5vw;
}

.title2 {
  font-family: "sy";
  font-size: 11vw;
  font-weight: bold;
  // height: 10vh;
}

.title2-2 {
  font-family: "sy";
  // font-size: 3rem;
  font-weight: bold;
  // height: 3rem;
}

.title3 {
  font-size: 8vw;
  font-weight: bold;
}

.title4 {
  padding-top: 1.5rem;
}

.title5 {
  font-weight: bold;
}

.title6 {
  font-family: "sy";
  font-size: 1.5rem;
}

.title7 {
  font-size: 0.8rem;
}

.title8-1 {
  font-family: "sy";
  font-size: 2rem;
}

.title8-2 {
  font-size: 1.1rem;
}


.floor {
  width: 100%;
  min-height: 100vh;
  position: relative;
  z-index: -1;
}

.trunk p {
  margin: 0;
}

.floor-back {
  position: relative;
  width: 100%;
  min-height: 100vh;
  background-position: 50%;
  background-repeat: no-repeat;
  background-size: cover;
  z-index: -1;
}

.floor-shadow {
  width: 100%;
  height: 100vh;
  position: absolute;
  z-index: 1;
}

.floor-core-absolute {
  width: 100%;
  height: auto;
  position: absolute;
  bottom: 5rem;
  left: 0;
  z-index: 10;
  display: flex;
  flex-direction: column;
  justify-content: end;
}

.floor-back-sticky {
  position: -webkit-sticky;
  position: sticky;
  width: 100%;
  min-height: 100vh;
  top: 0;
  background-position: 50%;
  background-repeat: no-repeat;
  background-size: cover;
  transition: 2s;
  z-index: 0;
}

.floor-core-sticky {
  background: transparent;
  position: -webkit-sticky;
  position: sticky;
  width: 100%;
  top: 0;
  min-height: 100vh;
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 1;
}

.floor-core-item {
  height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  position: relative;
  z-index: 50;
  // padding: 0 calc(1.3125rem + 0.75vw);
}

.floor-font {
  color: white;
  text-align: center;
  position: relative;
  z-index: 1000;
}

.carousel-item {
  position: relative;
  width: 100%;
  min-height: 100vh;
  background-position: 50%;
  background-repeat: no-repeat;
  background-size: cover;
  z-index: -1;
}

.floor-btns {
  width: 100%;
  height: auto;
  margin-top: 2rem;
  display: flex;
  justify-content: center;
  column-gap: 2rem;
}

.floor-btns-a {
  border: 1px solid white;
  border-radius: 20px;
  color: white;
  text-decoration: none;
  // width: 8.5rem;
  // height: 2.2rem;
  padding: 1px 5vw;
  display: flex;
  justify-content: center;
  align-items: center;
  font-size: 5vw;
}

.floor-btns-a:visited {
  color: white;
  text-decoration: none;
}

.floor-btns-a:active {
  color: white;
}

.btn-pink {
  border: 1px solid #FF4967;
  color: white;
  background-color: #FF4967;
}

.btn-through {
  border: 1px solid #0396BC;
  color: white;
  background-color: #0396BC;
}

.btn-blue-black {
  border: 1px solid #3066B2;
  color: white;
  background-color: #3066B2;
}

.btn-blue {
  border: 1px solid #1E31F1;
  color: white;
  background-color: #1E31F1;
}

.btn-black {
  border: 1px solid #4C4C4C;
  color: #4C4C4C;
}

.btn-black-back {
  border: 1px solid #4C4C4C;
  color: white;
  background-color: #4C4C4C;
}

.btn-orange {
  border: 1px solid #FF9300;
  color: white;
  background-color: #FF9300;
}

.btn-black:visited {
  color: #4C4C4C;
}

.btn-black:active {
  color: #4C4C4C;
}
</style>

<script>
export default {
  data() {
    return {
      isBigFont:false
    }
  },
  created() {
    this.windowsChange();
  },
  mounted() {
    window.onresize = () => {
      this.windowsChange();
      this.checkFont();
    }
  },
  methods: {
    windowsChange: function () {
      var cw = document.body.clientWidth;
      if (cw >= 850) {
        window.location.href = 'https://www.future.top'
      }
      // console.log(this.$route.name);
      // console.log(cw);
    },
    checkFont:function(){
      var owi = document.body.offsetWidth;
      var wi = window.screen.width;
      var res = (owi / wi).toFixed(2);

      //var fontSize = parseInt(window.getComputedStyle(document.body).fontSize, 10);
      // alert(fontSize + '_' + res);
      //if(res < 0.8 || fontSize > 18){
        this.isBigFont = true;
      //}else{
        this.isBigFont = false;
      //}
      this.isBigFont = res < 0.8 ? true : false;

    }
  }
}
</script>
